import { useState, useRef, useEffect, Fragment } from "react";
import "./index.css";
import { Disclosure } from "@headlessui/react";
import OpenAI from "openai";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { useTranslation } from "react-i18next";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
// import { Player, Controls } from "@lottiefiles/react-lottie-player";

// import LeftSideAd from "./LeftSideAd";
// import RightSideAd from "./RightSideAd";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_API_KEY,
  dangerouslyAllowBrowser: true,
});

var language = "English";

export default function App() {
  const bottomRef = useRef();
  const [renderedLanguage, setRenderedLanguage] = useState("English");
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const { t } = useTranslation();

  function preventEmptyMessage(e) {
    alert("Can't send an empty message.");
    e.preventDefault();
  }

  const chat = async (e, message) => {
    e.preventDefault();

    if (!message) return;
    setIsTyping(true);

    let msgs = chats;
    msgs.push({ role: "user", content: message });
    setChats(msgs);

    setMessage("");
    console.log(chats);

    console.log("Sending your user message...");

    await openai.chat.completions
      .create({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: `You are a longtime, conversational friend that only knows and responds in ${language}. Please do not communicate in a language that is not ${language}. Ask the user how they are doing instead of asking how you can help or assist them. Try to lead the conversation with intresting questions. Create and tell stories about yourself and your life."`,
          },
          ...chats,
        ],
        temperature: 0.3,
        max_tokens: 160,
        top_p: 1.0,
        frequency_penalty: 0.5,
      })
      .then((res) => {
        msgs.push(res.choices[0].message);
        setChats(msgs);
        setIsTyping(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chat]);

  useEffect(() => {
    document.body.addEventListener("keydown", detectKeyDown, true);
  }, []);

  const detectKeyDown = (e) => {
    document.getElementById("input").focus();
  };

  return (
    <>
      <div className="flex fixed flex-col items-center justify-center w-screen h-screen bg-gray-100 text-gray-800">
        <Disclosure as="nav" className="sticky w-screen bg-gray-100 top-0">
          {() => (
            <>
              <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                  {/*<Dropdown />*/}
                  <Menu
                    as="div"
                    className="sm:hidden fixed inline-block text-left"
                  >
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {t(renderedLanguage)}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="fixed z-50 -mt-.5 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            <button
                              className={
                                (renderedLanguage === "English"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "English";
                                setRenderedLanguage("English");
                                console.log(language);
                              }}
                            >
                              {t("English")}
                            </button>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              className={
                                (renderedLanguage === "Spanish"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "Spanish";
                                setRenderedLanguage("Spanish");
                                console.log(language);
                              }}
                            >
                              {t("Spanish")}
                            </button>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              className={
                                (renderedLanguage === "French"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "French";
                                setRenderedLanguage("French");
                                console.log(language);
                              }}
                            >
                              {t("French")}
                            </button>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              className={
                                (renderedLanguage === "Italian"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "Italian";
                                setRenderedLanguage("Italian");
                                console.log(language);
                              }}
                            >
                              {t("Italian")}
                            </button>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              className={
                                (renderedLanguage === "Portuguese"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "Portuguese";
                                setRenderedLanguage("Portuguese");
                                console.log(language);
                              }}
                            >
                              {t("Portuguese")}
                            </button>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              type="submit"
                              className={
                                (renderedLanguage === "German"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "German";
                                setRenderedLanguage("German");
                                console.log(language);
                              }}
                            >
                              {t("German")}
                            </button>
                          </Menu.Item>

                          <Menu.Item>
                            <button
                              type="submit"
                              className={
                                (renderedLanguage === "Russian"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "Russian";
                                setRenderedLanguage("Russian");
                                console.log(language);
                              }}
                            >
                              {t("Russian")}
                            </button>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              className={
                                (renderedLanguage === "Arabic"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "Arabic";
                                setRenderedLanguage("Arabic");
                                console.log(language);
                              }}
                            >
                              {t("Arabic")}
                            </button>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              className={
                                (renderedLanguage === "Hindi"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "Hindi";
                                setRenderedLanguage("Hindi");
                                console.log(language);
                              }}
                            >
                              {t("Hindi")}
                            </button>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              className={
                                (renderedLanguage === "Mandarin"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "Mandarin";
                                setRenderedLanguage("Mandarin");
                                console.log(language);
                              }}
                            >
                              {t("Mandarin")}
                            </button>
                          </Menu.Item>
                          <Menu.Item>
                            <button
                              className={
                                (renderedLanguage === "Japanese"
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm")
                              }
                              onClick={() => {
                                language = "Japanese";
                                setRenderedLanguage("Japanese");
                                console.log(language);
                              }}
                            >
                              {t("Japanese")}
                            </button>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start overflow-hidden">
                    <div className="flex flex-shrink-0 items-center">
                      <h1 className="font-bold text-xl">LangBuddy</h1>
                    </div>
                    <div className="hidden sm:ml-6 sm:block overflow-auto">
                      <div className="flex space-x-4">
                        <button
                          //  ref={sideRef}
                          className={
                            renderedLanguage === "English"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "English";
                            setRenderedLanguage("English");
                            console.log(language);
                          }}
                        >
                          {t("English")}
                        </button>
                        <button
                          className={
                            renderedLanguage === "Spanish"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "Spanish";
                            setRenderedLanguage("Spanish");
                            console.log(language);
                          }}
                        >
                          {t("Spanish")}
                        </button>
                        <button
                          className={
                            renderedLanguage === "French"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "French";
                            setRenderedLanguage("French");
                            console.log(language);
                          }}
                        >
                          {t("French")}
                        </button>
                        <button
                          className={
                            renderedLanguage === "Italian"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "Italian";
                            setRenderedLanguage("Italian");
                            console.log(language);
                          }}
                        >
                          {t("Italian")}
                        </button>
                        <button
                          className={
                            renderedLanguage === "Portuguese"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "Portuguese";
                            setRenderedLanguage("Portuguese");
                            console.log(language);
                          }}
                        >
                          {t("Portuguese")}
                        </button>
                        <button
                          className={
                            renderedLanguage === "German"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "German";
                            setRenderedLanguage("German");
                            console.log(language);
                          }}
                        >
                          {t("German")}
                        </button>
                        <button
                          className={
                            renderedLanguage === "Russian"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "Russian";
                            setRenderedLanguage("Russian");
                            console.log(language);
                          }}
                        >
                          {t("Russian")}
                        </button>
                        <button
                          className={
                            renderedLanguage === "Arabic"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "Arabic";
                            setRenderedLanguage("Arabic");
                            console.log(language);
                          }}
                        >
                          {t("Arabic")}
                        </button>
                        <button
                          className={
                            renderedLanguage === "Hindi"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "Hindi";
                            setRenderedLanguage("Hindi");
                            console.log(language);
                          }}
                        >
                          {t("Hindi")}
                        </button>
                        <button
                          className={
                            renderedLanguage === "Mandarin"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "Mandarin";
                            setRenderedLanguage("Mandarin");
                            console.log(language);
                          }}
                        >
                          {t("Mandarin")}
                        </button>
                        <button
                          className={
                            renderedLanguage === "Japanese"
                              ? "font-medium rounded-md text-sm py-2 px-3 bg-blue-600 text-white"
                              : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 rounded-md px-3 py-2 text-sm font-medium"
                          }
                          onClick={() => {
                            language = "Japanese";
                            setRenderedLanguage("Japanese");
                            console.log(language);
                          }}
                        >
                          {t("Japanese")}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="absolute space-x-0.5 inset-y-0 right-0 flex items-center pr-1 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <TwitterShareButton
                      url={"https://www.lang-buddy.com"}
                      quote={
                        "Try out this language learning app called LangBuddy! It lets you chat with a friendly AI bot to practice conversing in any language!"
                      }
                    >
                      <TwitterIcon size={25} round />
                    </TwitterShareButton>

                    <FacebookShareButton
                      url={"https://www.lang-buddy.com"}
                      quote={
                        "Try out this language learning app called LangBuddy! It lets you chat with a friendly AI bot to practice conversing in any language!"
                      }
                    >
                      <FacebookIcon size={25} round />
                    </FacebookShareButton>

                    <LinkedinShareButton
                      url={"https://www.lang-buddy.com"}
                      quote={
                        "Try out this language learning app called LangBuddy! It lets you chat with a friendly AI bot to practice conversing in any language!"
                      }
                    >
                      <LinkedinIcon size={25} round />
                    </LinkedinShareButton>

                    <RedditShareButton
                      url={"https://www.lang-buddy.com"}
                      quote={
                        "Try out this language learning app called LangBuddy! It lets you chat with a friendly AI bot to practice conversing in any language!"
                      }
                    >
                      <RedditIcon size={25} round />
                    </RedditShareButton>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="absolute bg-white bg-opacity-50 space-y-1 px-2 pb-3 pt-2">
                  <Disclosure.Button>
                    <button
                      className={
                        renderedLanguage === "English"
                          ? "bg-blue-600 text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "English";
                        setRenderedLanguage("English");
                        console.log(language);
                      }}
                    >
                      {t("English")}
                    </button>
                    <button
                      className={
                        renderedLanguage === "Spanish"
                          ? "bg-blue-600 text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "Spanish";
                        setRenderedLanguage("Spanish");
                        console.log(language);
                      }}
                    >
                      {t("Spanish")}
                    </button>
                    <button
                      className={
                        renderedLanguage === "French"
                          ? "bg-blue-600 text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "French";
                        setRenderedLanguage("French");
                        console.log(language);
                      }}
                    >
                      {t("French")}
                    </button>
                    <button
                      className={
                        renderedLanguage === "Italian"
                          ? "bg-blue-600 text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "Italian";
                        setRenderedLanguage("Italian");
                        console.log(language);
                      }}
                    >
                      {t("Italian")}
                    </button>
                    <button
                      className={
                        renderedLanguage === "Portuguese"
                          ? "bg-blue-600 text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "Portuguese";
                        setRenderedLanguage("Portuguese");
                        console.log(language);
                      }}
                    >
                      {t("Portuguese")}
                    </button>
                    <button
                      className={
                        renderedLanguage === "German"
                          ? "bg-blue-600 text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "German";
                        setRenderedLanguage("German");
                        console.log(language);
                      }}
                    >
                      {t("German")}
                    </button>
                    <button
                      className={
                        renderedLanguage === "Russian"
                          ? "bg-blue-600 text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "Russian";
                        setRenderedLanguage("Russian");
                        console.log(language);
                      }}
                    >
                      {t("Russian")}
                    </button>
                    <button
                      className={
                        renderedLanguage === "Arabic"
                          ? "bg-blue-600  text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "Arabic";
                        setRenderedLanguage("Arabic");
                        console.log(language);
                      }}
                    >
                      {t("Arabic")}
                    </button>
                    <button
                      className={
                        renderedLanguage === "Hindi"
                          ? "bg-blue-600 text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "Hindi";
                        setRenderedLanguage("Hindi");
                        console.log(language);
                      }}
                    >
                      {t("Hindi")}
                    </button>
                    <button
                      className={
                        renderedLanguage === "Mandarin"
                          ? "bg-blue-600 text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "Mandarin";
                        setRenderedLanguage("Mandarin");
                        console.log(language);
                      }}
                    >
                      {t("Mandarin")}
                    </button>
                    <button
                      className={
                        renderedLanguage === "Japanese"
                          ? "bg-blue-600 text-white block rounded-md px-3 py-2 text-base font-medium"
                          : "text-blue-600 hover:bg-gray-200 hover:text-blue-600 block rounded-md px-3 py-2 text-base font-medium"
                      }
                      onClick={() => {
                        language = "Japanese";
                        setRenderedLanguage("Japanese");
                        console.log(language);
                      }}
                    >
                      {t("Japanese")}
                    </button>
                  </Disclosure.Button>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <div className="flex flex-col flex-grow w-full max-w-xl pb-40 md:pb-30 lg:pb-30 bg-white shadow-xl rounded-lg overflow-hidden">
          {/*<iframe
            className="flex flex-col flex-grow h-screen"
            src="https://lottie.host/?file=c6770b68-1822-49f2-8b56-c44b7c6a70c0/AYjUFgQFbf.json"
                    />
          <h2 className="flex fixed flex-col flex-grow">
            Start the conversation!
          </h2>*/}
          <div className="flex flex-col flex-grow h-0 p-4 overflow-auto">
            {chats.map((chat, index) =>
              chat.role === "user" ? (
                <div
                  key={index}
                  className="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end"
                >
                  <div>
                    <div className="bg-blue-600 text-white p-3 rounded-l-lg rounded-br-lg">
                      <p className="text-sm">
                        {chat.content} {""}
                      </p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                </div>
              ) : (
                <div
                  key={index}
                  className="flex w-full mt-2 space-x-3 max-w-xs"
                >
                  <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300" />
                  <div>
                    <div className="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                      <p className="text-sm">
                        {chat.content} {""}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}

            <div
              className={
                isTyping ? "flex w-full mt-2 space-x-3 max-w-xs" : "invisible"
              }
            >
              <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300" />
              <div>
                <div className="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg">
                  <p className="text-sm">
                    {isTyping ? ". . ." : ""} {""}
                  </p>
                </div>
              </div>
            </div>

            <div ref={bottomRef}></div>
          </div>

          <form
            className="fixed bottom-0 w-full max-w-xl bg-gray-300 p-4"
            onSubmit={(e) =>
              message === "" ? preventEmptyMessage(e) : chat(e, message)
            }
          >
            <div className="flex sticky">
              <input
                type="text"
                id="input"
                name="message"
                value={message}
                className="flex h-14 w-full pl-2 pr-24 rounded-lg z-0 focus:shadow focus:outline-none"
                placeholder={t("placeholder")}
                onChange={(e) => setMessage(e.target.value)}
              />
              <div className="flex absolute top-2 right-2">
                <button
                  type="submit"
                  className="h-10 w-20 text-white rounded-lg bg-blue-600 hover:bg-blue-700"
                >
                  {t("sendButton")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
