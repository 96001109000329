import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { options } from "./languageDetectorConfig";

i18next.use(initReactI18next, LanguageDetector).init({
  detection: options,
  fallbackLng: "en",
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    es: {
      translations: require("./locales/es/translations.json"),
    },
    de: {
      translations: require("./locales/de/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18next.languages = ["en", "es", "de"];

export default i18next;
